module.exports = [{
      plugin: require('../node_modules/gatsby-v2-plugin-page-transitions/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-54779917-3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fable & Faber","short_name":"Fable & Faber","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/assets/images/logo-round.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e1eb9db9c1092f240f9d6712a35843df"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2400,"withWebp":true,"quality":100,"linkImagesToOriginal":false,"backgroundColor":"#ffffff"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
